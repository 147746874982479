import "./link-icon.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("link-icon");

export interface LinkIconProps {
  id?: string;
  alt: string;
  src: string;
  href: string;
}

const LinkIcon: React.FC<LinkIconProps> = ({ alt, id = "", src, href }) => {
  return (
    <div id={id}>
      <a className={bem.block()} href={href} target="_blank" rel="noreferrer">
        <img src={src} alt={alt} />
      </a>
    </div>
  );
};

export default LinkIcon;
