import "./project-list.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("project-list");

export interface ProjectItem {
  name: string;
  description: string;
  location: string;
  team: string;
  role: string;
}

export interface ProjectListProps {
  title: string;
  projects: ProjectItem[];
}

const ProjectList: React.FC<ProjectListProps> = ({ title, projects }) => {
  const itemList = projects.map((project, index) => (
    <div className={bem.elem("item")} key={`${project.name}`}>
      <span className={bem.elem("name")}>{project.name}</span>
      <br />
      <div className={bem.elem("description")}>{project.description}</div>
      <br />
      <div className={bem.elem("location")}>
        <span className={bem.elem("role")}>{project.role}</span>
        <br />
        <span className={bem.elem("team")}>
          {project.location}, {project.team}
        </span>
      </div>
    </div>
  ));

  return (
    <div className={bem.block()}>
      <h4>{title}</h4>
      {itemList}
    </div>
  );
};

export default ProjectList;
