import "./billboard-a.scss";
import React from "react";
import { QBem } from "qbem";
import Block from "../Block/Block";
import ImageBadge from "../ImageBadge/ImageBadge";
import QuoteBlock from "../QuoteBlock/QuoteBlock";
import SkillsDisplay from "../SkillsDisplay/SkillsDisplay";

const bem = new QBem("billboard-a");

const BillboardA: React.FC = () => {
  return (
    <Block className={bem.block()}>
      <div className={`small-none`}>
        <div className={bem.elem("images-primary")}>
          <img src="./images/oracle.png" alt="Oracle Cloud Infrastructure" />
          <img src="./images/pinterest.svg" alt="Pinterest" />
        </div>

        <div className={`${bem.elem("images-secondary")} medium-none`}>
          <img src="./images/ford-motor.jpg" alt="Ford Motors" />
          <img src="./images/vml.webp" alt="VML" />
          <img src="./images/cabelas.png" alt="Cabela's" />
        </div>
      </div>

      <div className={`${bem.elem("images-primary")} medium-none`}>
        <img src="./images/oracle.png" alt="Oracle Cloud Infrastructure" />
        <img src="./images/pinterest.svg" alt="Pinterest" />
      </div>

      <div className={bem.elem("nametag")}>
        <h2>Sr. Software Engineer</h2>
        <hr />
        <h4>Greater Seattle Area, USA</h4>
      </div>

      <div className={bem.elem("images-secondary")}>
        <img src="./images/ford-motor.jpg" alt="Ford Motors" />
        <img src="./images/vml.webp" alt="VML" />
        <img src="./images/cabelas.png" alt="Cabela's" />
      </div>

      <div className={bem.elem("content")}>
        <div className={bem.elem("about")}>
          <div>
            <b>Frontend Specialist, Full-Stack Experience</b>
          </div>
          <hr />
          <SkillsDisplay
            skills={[
              "Typescript",
              "React",
              "Javascript",
              "BlueprintJS",
              "Jest",
              "Cypress",
              "Webpack",
              "NodeJS",
              "State Management",
              "REST",
            ]}
          />
        </div>
      </div>

      <div className={bem.elem("primary-info")}>
        <div className={bem.elem("tagline")}>
          <h3>Unmatched Experience, Ready to Drive Impact NOW</h3>
        </div>

        <ImageBadge
          src="./images/scott-linenberger.jpeg"
          alt="Scott Linenberger, Sr. Software Engineer"
        />
      </div>

      <Block className={bem.elem("quote")}>
        <QuoteBlock author="Scott Linenberger">
          I have experience with teams of every shape and size in multiple
          industries from health care technology to cloud infrastructure to
          social media, with a long history of building POCs, shipping features
          and leveling up engineers.
        </QuoteBlock>
      </Block>

      <div>
        {/* <div className={bem.elem("stack")}>
                React, Typescript, Javascript, BlueprintJS, State Management
                (Redux/React + Hooks), Jest, Cypress, Webpack, NodeJS, Express,
                Engineering Excellence
              </div> */}
        <div>
          Application Architecture, Internal Library Dev & Maintenance,
          Developer Tooling, Unit Testing
          <b>
            Management + Team Lead Experience & Proven Record of Leveling-Up
            Engineers & Teams
          </b>
        </div>
      </div>
    </Block>
  );
};

export default BillboardA;
