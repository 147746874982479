import "./skills-display.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("skills-display");

export interface SkillsDisplayProps {
  skills: string[];
}

const SkillsDisplay: React.FC<SkillsDisplayProps> = ({ skills }) => {
  return (
    <div className={bem.block()}>
      {skills.map((currSkill) => (
        <div className={bem.elem("skill")} key={currSkill}>
          {currSkill}
        </div>
      ))}
    </div>
  );
};

export default SkillsDisplay;
