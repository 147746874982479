import React from "react";
import "./css/html-css-reset.css";
import "./css/colors.scss";
import "./App.scss";
import { QBem } from "qbem";
import PageHeader from "./components/PageHeader/PageHeader";
import BillboardA from "./components/BillboardA/BillboardA";
import BillboardB from "./components/BillboardB/BillboardB";
import PageFooter from "./components/PageFooter/PageFooter";
import BillboardC from "./components/BillboardC/BillboardC";

const bem = new QBem("app");

function App() {
  return (
    <div id="top" className={bem.block()}>
      <PageHeader />
      <BillboardA />
      <BillboardB />
      <BillboardC />
      <PageFooter />
    </div>
  );
}

export default App;
