import "./page-footer.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("page-footer");

const PageFooter: React.FC = () => {
  return (
    <div className={bem.block()}>
      <div className={bem.elem("details")}>
        <div>Sr. Software Engineer</div>
        <div>Greater Seattle Area, USA</div>
      </div>
      <div className={bem.elem("author")}>Scott Linenberger - 2025</div>
    </div>
  );
};

export default PageFooter;
