import "./billboard-c.css";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("billboard-c");

const BillboardC: React.FC = () => {
  return <div className={bem.block()}>Hello, world</div>;
};

export default BillboardC;
