import "./local-nav.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("local-nav");

const LocalNav: React.FC = () => {
  return (
    <>
      <div className={bem.block()}>
        <div className={bem.elem("items")}>
          <div className={bem.element("item")}>
            <a href="#feature-builder">#Feature Builder</a>
          </div>
          <div className={bem.element("item")}>
            <a href="#problem-solver">#Relentless Problem Solver</a>
          </div>
          <div className={bem.element("item")}>
            <a href="#contributor">#Contributor</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalNav;
