import "./page-header.scss";
import React, { useState } from "react";
import { QBem } from "qbem";
import Block from "../Block/Block";
import NavBar from "../NavBar/NavBar";
import LocalNav from "../LocalNav/LocalNav";

const bem = new QBem("page-header");

const PageHeader: React.FC = () => {
  /* setup show/hide functionality */
  const [isHidden, setHidden] = useState(true);

  const setScrollingEnabled = (isEnabled: boolean) => {
    document.body.style.overflow = isEnabled ? "unset" : "hidden";
  };

  const goToAnchor = (anchor: string) => {
    /* enable scrolling */
    setScrollingEnabled(true);
    /* change href */
    window.location.href = `${window.location.origin}/${anchor}`;
  };

  return (
    <>
      <div className={bem.elem("hidden-header")}>
        <h3>@Scott-Linenberger</h3>
        {isHidden && (
          <div
            className={bem.elem("hidden-nav")}
            onClick={() => {
              /* disable scrolling */
              setScrollingEnabled(false);

              /* show the mobile nav */
              setHidden((p) => false);
            }}
          >
            Navigation
          </div>
        )}
      </div>
      <div id="top" className={bem.block()}>
        <Block className={bem.element("header")}>
          <h1>Scott Linenberger</h1>
          <h4>Sr. Software Engineer</h4>
        </Block>
      </div>
      <NavBar />
      <LocalNav />
      {!isHidden && (
        <>
          <div className={bem.elem("glass")}></div>
          <div className={bem.elem("mobile-nav")}>
            <div
              className={bem.elem("nav-item")}
              onClick={() => {
                /* enable scrolling */
                setScrollingEnabled(true);

                /* hide the mobile nav */
                setHidden((p) => true);
              }}
            >
              Back to Page
            </div>
            <div
              className={bem.elem("nav-item")}
              onClick={() => {
                /* hide nav */
                setHidden((p) => true);
                /* go to anchor */
                goToAnchor("#top");
              }}
            >
              TOP
            </div>
            <div
              className={bem.elem("nav-item")}
              onClick={() => {
                /* hide nav */
                setHidden((p) => true);
                /* go to anchor */
                goToAnchor("#feature-builder");
              }}
            >
              Strong IC
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PageHeader;
