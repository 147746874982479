import "./terminal-window.scss";
import React, { ReactNode, useEffect } from "react";
import { QBem } from "qbem";

const bem = new QBem("terminal-window");

export interface TerminalWindowProps {
  children?: ReactNode;
  className?: string;
}

const TIME_BLINK = 1000;

const TerminalWindow: React.FC<TerminalWindowProps> = ({
  children,
  className,
}) => {
  const [isOn, setOn] = React.useState(false);

  useEffect(() => {
    /* toggle the cursor */
    const blinkInterval = setTimeout(() => {
      setOn((isOn) => !isOn);
    }, TIME_BLINK);

    return () => {
      clearTimeout(blinkInterval);
    };
  }, [isOn]);

  return (
    <div className={bem.block(null, className || "")}>
      <div className={bem.element("control-bar")}>
        <div className={bem.elem("btn1")}></div>
        <div className={bem.elem("btn2")}></div>
        <div className={bem.elem("btn3")}></div>
        <div className={bem.elem("title")}>user@{navigator.userAgent}</div>
      </div>
      <div className={bem.elem("window")}>{children}</div>
      <div className={bem.element("input")}>
        <span className={bem.elem("arrow")}>&rarr;</span>
        <span className={bem.elem("repo")}>scottlinenberger-2025</span>&nbsp;
        <span className={bem.elem("app")}>
          git:(<span className={bem.elem("branch")}>main</span>)
          {isOn && <div className={bem.elem("cursor")}></div>}
        </span>
      </div>
    </div>
  );
};

export default TerminalWindow;
