import "./block.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("block");

export interface BlockProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  fullscreen?: boolean;
}

const Block: React.FC<BlockProps> = ({
  children,
  className = "",
  fullscreen,
  id,
}) => {
  return (
    <div
      id={id}
      className={bem.block([{ fullscreen: !!fullscreen }], className)}
    >
      <div className={bem.elem("content")}>{children}</div>
    </div>
  );
};

export default Block;
