import "./work-highlight.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("work-highlight");

export interface WorkHighlightProps {
  title: string;
  children?: React.ReactNode;
}

const WorkHighlight: React.FC<WorkHighlightProps> = ({ children, title }) => {
  return (
    <div className={bem.block()}>
      <div className={bem.elem("title")}>
        <h3>{title}</h3>
      </div>
      <hr />
      <div className={bem.elem("content")}>{children}</div>
    </div>
  );
};

export default WorkHighlight;
