import "./quote-block.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("quote-block");

export interface QuoteBlockProps {
  className?: string;
  children: React.ReactNode;
  author: string;
}

const QuoteBlock: React.FC<QuoteBlockProps> = ({
  author,
  children,
  className,
}) => {
  return (
    <>
      <div className={bem.block()}>
        <span className={bem.elem("opencloser")}>&quot;</span>
        <div className={bem.elem("text")}>{children}</div>
        <span className={bem.elem("opencloser")}>&quot;</span> <br />
      </div>
      <div className={bem.elem("author")}>
        <span className={bem.elem("origin")}> - {author}</span>
      </div>
    </>
  );
};

export default QuoteBlock;
