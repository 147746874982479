import "./billboard-b.scss";
import React from "react";
import { QBem } from "qbem";
import Block from "../Block/Block";
import WorkHighlight from "../WorkHighlight/WorkHighlight";
import ProjectList from "../ProjectList/ProjectList";
import TerminalWindow from "../TerminalWindow/TerminalWindow";
import STRINGS from "../../constants/constants-text";

const bem = new QBem("billboard-b");

export interface BillboardBProps {}

const BillboardB: React.FC<BillboardBProps> = () => {
  return (
    <Block className={bem.block()} fullscreen={true}>
      <TerminalWindow className={bem.elem("terminal")}>
        <div className={bem.elem("ascii")}>
          <pre>{STRINGS.ASCII_SCOTT_LINENBERGER}</pre>
        </div>
      </TerminalWindow>
      <div className={bem.elem("title")}>
        <div className={bem.elem("accent")}>&gt;&gt;</div>
        <div>
          <h2>STRONG INDIVDUAL CONTRIBUTOR (IC)</h2>
          <hr />
          <h3 className={bem.elem("tagline")}>IMPACT - GROWTH - EXCELLENCE</h3>
        </div>
        <div className={bem.elem("accent")}>&lt;&lt;</div>
      </div>

      <WorkHighlight title="Feature Builder">
        <div id="feature-builder">
          <h3>Objectives, Requirements, Execution</h3>
          <p>
            Scott has a history of driving projects and features from early,
            ambiguous concept phases through final delivery by combining his
            engineering skills with business knowledge, customer empathy, and
            pragmatic action. When building new features and apps, Scott aligns
            with leadership and product to build a clear picture of the
            objective, understanding how it aligns with larger OKRs (objectives
            and key results).
          </p>
        </div>

        <ProjectList
          title="Feature & App Builder"
          projects={[
            {
              name: "T.U.M.s",
              description:
                "Delivered the first usability metric for Pinterest's scale advertiser tools in ~4 months from start date through product development, requirements gathering, building a strong relationship with Data Scientists, and coordinating engineering efforts. Creation of this metric aligned directly with high-level OKRs focused on improving advertiser experience to drive increased advertiser spend in a $300M+ per year product.",
              location: "Pinterest",
              team: "Advertising at Scale - ASC",
              role: "Engineering Manager",
            },
            {
              name: "Horizon Data Rules Editor",
              description:
                "Delivered a working MVP of the Horizon rules editor from preliminary designs in ~5 months through hands-on React Development, testing, extensive training of offshore resources and coordination of onshore services teams. ",
              location: "Tegria/Multiscale/KenSci",
              team: "Team Horizon",
              role: "Lead Frontend Application Architect / Eng Manager",
            },
            {
              name: "Cabela's In-Store Hub Kiosk",
              description:
                "Developed and delivered primary, hero UI features for Cabela's in-store kiosk in ~4 months from work start until first in-store deployment through utilizing AngularJS, redux, D3 and Javascript web workers with custom logic. ",
              location: "VML",
              team: "Project: Cabela's",
              role: "Sr. Software Engineer, Co-UI Lead",
            },
          ]}
        />
      </WorkHighlight>

      <WorkHighlight title="Relentless Problem Solver">
        <div id="problem-solver"></div>
        <ProjectList
          title="Strategic Wins for Critical Teams"
          projects={[
            {
              name: "Advertising at Scale",
              description:
                "As a new employee, within 90 days of starting the company, Scott accepted a promotion & team change with 50% increase in direct reports and a mission to demonstrably improve an embattled team of 10+ engineers struggling to keep production incidents under control and historically unable to provide delivery dates or accurate estiamtes for deliverables. Within ~7 weeks, I successfully demonstrated, via metrics, step function improvement in on-time delivery, reduction of incidents, reduction of on-call response time, and an incrase in engineering focus time and output accomplished through strong coordination, project management, wearing multiple hats, relentless tactical planning, streamlined communication and dedicated teamwork.",
              location: "Pinterest",
              team: "Advertising at Scale - ASC",
              role: "Engineering Manager",
            },
            {
              name: "Offer Management",
              description:
                'As a junior Engineering Manager, Scott took ownership of the fragmented Offer Management Team, leading them in delivering a strategic "Boulder" project through a challenging acquision, difficult re-org, severe team attrition, planned layoffs, and dramatic equity losses due to a downturn in the housing market. Scott helped the Offer Management team achieve this through alignment, collaboration, organization, on-call training, process definition, excellence and guiding engineers in operating with professional efficiency and skill.',
              location: "Compass Inc.",
              team: "Offer Management",
              role: "Engineering Manager/Team Lead",
            },
          ]}
        />
      </WorkHighlight>

      <WorkHighlight title="Contributor">
        <div id="contributor"></div>
        <ProjectList
          title="Initiatives"
          projects={[
            {
              name: "Engineering Tiger Team",
              description:
                "Supported engineering output and timelines by volunteering to catalog, own and eliminate blocking problems as part of a Tiger Team. The Tiger Team directly contributed to eliminating persistent CICD and developer environment issues through problem-solving, partnership, ownership and accountability, resulting in return of lost engineering output and on-time delivery of strategic boulder projects. ",
              location: "Compass Inc.",
              team: "Offer Management",
              role: "Engineering Manager/Team Lead",
            },
            {
              name: "Ops-Wide Excellence Initiative",
              description:
                "Reduced failed deployments and production bugs in critical Dev Ops Portal application used to control and maintain the Orcale Cloud, through dramatically improving test posture and increasing code quality. Accomplished by establishing a strong test bar with accurate, measurable coverage metrics, contributing hundreds of unit tests, refactoring code to reduce bugs and make it testable and through coaching external teams in writing better React apps and components.  ",
              location: "Oracle Cloud Infrastructure",
              team: "Dev Ops Portal Experience - DOPE",
              role: "Sr. Software Engineer",
            },
          ]}
        />
      </WorkHighlight>
    </Block>
  );
};

export default BillboardB;
