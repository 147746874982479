import "./nav-bar.scss";
import React from "react";
import { QBem } from "qbem";
import LinkIcon from "../LinkIcon/LinkIcon";

const bem = new QBem("nav-bar");

const NavBar: React.FC = () => {
  return (
    <div className={bem.block()}>
      <div className={bem.elem("link-icons")}>
        <LinkIcon
          alt="Github"
          src="./images/github.png"
          href="https://github.com/scott-linenberger"
        />
        <LinkIcon
          alt="NPMJS"
          src="./images/npm.svg"
          href="https://www.npmjs.com/~scott-linenberger"
        />
        <LinkIcon
          id="link-li"
          alt="LinkedIn"
          src="./images/linkedin.png"
          href="https://www.linkedin.com/in/scott-linenberger-0b079852/"
        />
      </div>
    </div>
  );
};

export default NavBar;
