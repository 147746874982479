import "./image-badge.scss";
import React from "react";
import { QBem } from "qbem";

const bem = new QBem("image-badge");

export interface ImageBadgeProps {
  src: string;
  alt: string;
}

const ImageBadge: React.FC<ImageBadgeProps> = ({ src, alt }) => {
  return (
    <div className={bem.block()}>
      <img src={src} alt={alt} />
      <div className={bem.elem("backer")}></div>
    </div>
  );
};

export default ImageBadge;
